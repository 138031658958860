* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	min-height: 100%;
	width: 100%;
	overflow-x: hidden;
}
#root,
body {
	padding: 0 !important;
	min-height: 100%;
	width: 100%;
}
